import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';
import Button from '../common/components/Button';
import Modal from '../common/containers/Modal';

class YoutubeVideo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
    };
    this.togglePlayer = this.togglePlayer.bind(this);
    this.scrollVideoIntoView = this.scrollVideoIntoView.bind(this);
    const { snippet } = this.props.video;
    this.snippet = snippet;
    this.src = `https://www.youtube.com/embed/${snippet.resourceId.videoId}?list=${snippet.playlistId}&autoplay=1`;
    this.titleRef = React.createRef();
  }

  togglePlayer() {
    this.setState((state) => ({ ...state, isOpen: !state.isOpen }));
  }

  scrollVideoIntoView() {
    // if (this.titleRef.current) {
    //     this.titleRef.current.scrollIntoView();
    // }
  }

  render() {
    const { i18n } = this.props;
    return (
      <>
        <h4 ref={this.titleRef}>
          <a role="button" onClick={() => this.togglePlayer()}>
            <i className="icon mdi mdi-videocam" /> {this.snippet.title}
          </a>
          &nbsp;
          {this.state.isOpen && (
            <Button size="xs" onClick={this.togglePlayer}>
              {i18n.t('Fechar')}
            </Button>
          )}
        </h4>

        {this.props.showThumbnail && (
          <div className="thumbnail">
            <a role="button">
              <img src={this.snippet.thumbnails.high.url} onClick={() => this.togglePlayer()} />
            </a>
            <div className="caption">
              <h5>
                <a role="button" onClick={() => this.togglePlayer()}>
                  {this.snippet.title}
                </a>
              </h5>
            </div>
          </div>
        )}

        {this.state.isOpen && (
          <Modal
            title={this.snippet.title}
            show={this.state.isOpen}
            customWidth="85vw"
            fullScreen={true}
            closeOnClickOutside={true}
            onCancel={() => this.setState((state) => ({ ...state, isOpen: false }))}
          >
            <iframe
              style={{ height: '75vh' }}
              width="100%"
              src={this.src}
              frameBorder="0"
              allowFullScreen
              onLoad={this.scrollVideoIntoView}
            />
          </Modal>
        )}
      </>
    );
  }
}

YoutubeVideo.propTypes = {
  video: PropTypes.object.isRequired,
  showThumbnail: PropTypes.bool,
};

export default withNamespaces()(YoutubeVideo);
