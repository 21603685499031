import React, { Component } from 'react';
import { withNamespaces } from 'react-i18next';
import Col from '../common/containers/Col';
import Content from '../common/containers/Content';
import Panel from '../common/containers/Panel';
import PublicPage from '../common/containers/PublicPage';
import Row from '../common/containers/Row';
import Videos from './Videos';

class VideosPage extends Component {
  render() {
    const { i18n } = this.props;
    return (
      <PublicPage title={i18n.t('Vídeos')}>
        <Content>
          <Row>
            <Col md={10} mdOffset={1}>
              <Panel>
                <Videos />
              </Panel>
            </Col>
          </Row>
        </Content>
      </PublicPage>
    );
  }
}

export default withNamespaces()(VideosPage);
